<template>
  <div class="dates">
    <!-- <a href="https://www.songkick.com/artists/9601444" class="songkick-widget" data-theme="dark" data-track-button="on" data-detect-style="true" data-font-color="#ffffff" data-background-color="transparent" data-locale="en">Indecent Behavior tour dates</a>
    <component :is="'script'" src="//widget.songkick.com/9601444/widget.js" async></component>
     -->
    <div class="date" v-for="(date, i) in dates" :key="i">
      <div style="font-size: 12px; margin-bottom: 8px">
        {{date.day}} {{date.date}} <span style="opacity: 0.5" v-if="date.time !== ''">• {{date.time}}</span>
      </div>
      <div style="font-size: 18px; margin-bottom: 4px">
        {{ date.city }}
      </div>
      <div style="font-size: 14px;">
        {{ date.club }}
      </div>
      <div v-if="date.tickets !== ''">
        <v-btn v-if="$vuetify.breakpoint.smAndUp && !date.soldout" @click="navigate(date.tickets)" style="position: absolute; right: 30px; top: 35px; text-transform: none; padding: 0 40px">
          Tickets
        </v-btn>
        <v-btn v-if="$vuetify.breakpoint.xs && !date.soldout" @click="navigate(date.tickets)" style="text-transform: none; width: 100%; margin-top: 20px">
          Tickets
        </v-btn>
        <span v-if="$vuetify.breakpoint.smAndUp && date.soldout" style="font-size: 20px; font-weight: 600; position: absolute; right: 8px; top: 45px; text-transform: none; padding: 0 40px; color: red">
          SOLD OUT
        </span>
        <div v-if="$vuetify.breakpoint.xs && date.soldout" style="font-size: 20px; font-weight: 600; text-transform: none; width: 100%; margin-top: 10px; color: red; text-align: center">
          SOLD OUT
        </div>
      </div>
      <div v-else style="position: absolute; right: 30px; top: 35px; max-width: 130px; text-transform: none; padding: 0 0px">
        No presale, tickets available at the door only
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dates: [
      {
        day: 'FRI',
        date: '11 APRIL 2025',
        time: '',
        city: 'Karlsruhe, Germany',
        club: 'AKK',
        tickets: '',
        soldout: false
      },
      {
        day: 'SAT',
        date: '11 MAY 2025',
        time: '',
        city: 'COPPER CORE METALFEST',
        club: 'Burg Ohrdruf, Germany',
        tickets: 'https://coppercore-festival.com/',
        soldout: false
      },
      {
        day: 'SUN',
        date: '29 JUNE 2025',
        time: '',
        city: 'MOOS MEADOW FESTIVAL',
        club: 'Feuchtwangen, Germany',
        tickets: 'https://meadowfestival.reservix.de/events',
        soldout: false
      },
      {
        day: 'SAT',
        date: '26 JULY 2025',
        time: '',
        city: 'RIEZ OPEN AIR',
        club: 'Bausendorf, Germany',
        tickets: 'https://riez.de/tickets/',
        soldout: false
      },
      {
        day: 'WED - SAT',
        date: '06 - 10 AUGUST 2025',
        time: '',
        city: 'OPEN FLAIR FESTIVAL',
        club: 'Eschwege, Germany',
        tickets: 'https://shop.open-flair.de/produkte/376-tickets-open-flair-festival-2025-eschwege-eschwege-am-06-08-2025',
        soldout: false
      },
      {
        day: 'FRI',
        date: '24 OCTOBER 2025',
        time: '',
        city: 'Göttingen, Germany',
        club: 'Exil',
        tickets: 'https://shop.indecent-behavior.com/tickets/indecent-behavior-hardticket-exil-goettingen-24-10-2025',
        soldout: false
      },
      {
        day: 'SAT',
        date: '25 OCTOBER 2025',
        time: '',
        city: 'Hannover, Germany',
        club: 'Lux',
        tickets: 'https://shop.indecent-behavior.com/tickets/hardticket-lux-hannover-25-10-2025',
        soldout: false
      },
      {
        day: 'THU',
        date: '06 NOVEMBER 2025',
        time: '',
        city: 'Köln, Germany',
        club: 'Tsunami',
        tickets: 'https://shop.indecent-behavior.com/tickets/hardticket-tsunami-koeln-06-11-2025',
        soldout: false
      },
      {
        day: 'FRI',
        date: '07 NOVEMBER 2025',
        time: '',
        city: 'Berlin, Germany',
        club: 'Badehaus',
        tickets: 'https://shop.indecent-behavior.com/tickets/hardticket-badehaus-berlin-07-11-2025',
        soldout: false
      },
      {
        day: 'SAT',
        date: '08 NOVEMBER 2025',
        time: '',
        city: 'Dortmund, Germany',
        club: 'FZW',
        tickets: 'https://shop.indecent-behavior.com/tickets/hardticket-fzw-dortmund-08-11-2025',
        soldout: false
      },
      {
        day: 'THU',
        date: '20 NOVEMBER 2025',
        time: '',
        city: 'Frankfurt, Germany',
        club: 'Nachtleben',
        tickets: 'https://shop.indecent-behavior.com/tickets/hardticket-nachtleben-frankfurt-am-main-20-11-2025',
        soldout: false
      },
      {
        day: 'FRI',
        date: '21 NOVEMBER 2025',
        time: '',
        city: 'Hamburg, Germany',
        club: 'Indra',
        tickets: 'https://shop.indecent-behavior.com/tickets/hardticket-indra-hamburg-21-11-2025',
        soldout: false
      },
      {
        day: 'SAT',
        date: '22 NOVEMBER 2025',
        time: '',
        city: 'Erfurt, Germany',
        club: 'Engelsburg',
        tickets: 'https://shop.indecent-behavior.com/tickets/hardticket-engelsburg-erfurt-22-11-2025',
        soldout: false
      },
      {
        day: 'THU',
        date: '27 NOVEMBER 2025',
        time: '',
        city: 'Dresden, Germany',
        club: 'Ostpol',
        tickets: 'https://shop.indecent-behavior.com/tickets/hardticket-ostpol-dresden-27-11-2025',
        soldout: false
      },
      {
        day: 'FRI',
        date: '28 NOVEMBER 2025',
        time: '',
        city: 'Osnabrück, Germany',
        club: 'Westwerk',
        tickets: 'https://shop.indecent-behavior.com/tickets/hardticket-westwerk-osnabrueck-28-11-2025',
        soldout: false
      },
      {
        day: 'SUN',
        date: '21 DECEMBER 2025',
        time: '',
        city: 'Saarbrücken, Germany',
        club: 'Garage - Feels Like Home 2025',
        tickets: 'https://shop.indecent-behavior.com/neu/hardticket-saarbruecken-garage-21-12-2025',
        soldout: false
      }
      ]
    }
  },
  methods: {
    navigate (link) {
      window.open(link, '_blank')
    }
  }
}
</script>

<style scoped>
@media only screen and (min-width: 64.963em) {
  .dates {
    padding: 100px 5%;
    background-image: url('~@/assets/BSPark.jpg');
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    color: white;
  } 
}

.dates {
  padding: 100px 5%;
  color: white;
}

.dates::before {
    content: ' ';
    display: block;
    background-image: url('~@/assets/BSPark.jpg');
    background-position: center;
    background-size: cover;
    height: 100vh; width: 100vw;
    top: 0; bottom: 0; left: 0; right: 0;
    position: fixed;
    z-index: -10;
}

.date {
  width: 100%;
  max-width: 1000px;
  background-color: #0000008a;
  padding: 25px;
  text-align: left;
  position: relative;
  margin: 10px auto;
  border-radius: 5px
}
</style>
